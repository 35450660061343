.hero {
  background-color: #f7fafa;
}
.hero__title {
  font-size: 36px;
  font-weight: bold;
}
.hero__paragraph {
 font-size: 18px;
 margin-bottom: 6px;
}

.hero__btns {
display: flex;
flex-direction: column;
}

.hero__btn {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 0;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}
.hero__btn {
  transition: all .3s;
}
.hero__btn1 {
  background-color: #f89646;
  border: 1px solid #f89646;
}
.hero__btn1:hover {
  background-color: #fff;
  color: #f89646;
}
.hero__btn2 {
  background-color: #6bb7be;
  border: 1px solid #6bb7be;
}
.hero__btn2:hover {
  background-color: #fff;
  color: #6bb7be;
}

.hero__img {
padding: 5px;
margin-top: 6px;
}

.hero__img img {
  width: 100%;
}

@media(min-width: 630px) {
  .hero__btns {
    flex-direction: row;
    }
    .hero__btn {
      width: 204px;
    }
    .hero__img img {
      width: 82%;
  }
}

@media(min-width: 768px) {
  .hero {
    display: flex;
    justify-content: space-between;
  }
  .hero__text {
    max-width: 40%;
    padding-top: 20px;
  }
  .hero__img {
    max-width: 55%;
    text-align: center;
  }
  .hero__img img {
    width: 90%;
}
  .hero__title {
    font-size: 40px;
  }
  .section3 {
    flex-direction: row-reverse;
  }
}


@media(min-width: 1800px) {
  .hero {
    justify-content: space-evenly;
  }
  .hero__text {
    padding-top: 40px;
  }
  .hero__title {
    font-size: 60px;
  }
  .hero__paragraph {
    font-size: 22px;
  } 
 .hero__btn {
  width: 222px;
  font-size: 22px;
 }
 .hero__img img {
  width: 76%;
}
}


/* SECTION 2 */
.section2__title {
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 50px !important;
}
.section2__box {
  border: 1px solid #f7fafa;
  margin-bottom: 22px;
}
.section2__box-img img {
  width: 100%;
  height: 140px;
  object-fit: cover;
  object-position: 30% 28%;

}
.section2__box-text {
  padding: 12px;
}
.section2__box-title {
  font-size: 18px;
  margin: 12px 0;
}
.section2__box-paragraph {
  margin: 0;
}

@media(min-width: 768px) {
  .section2__title {
margin-bottom: 20px;
  }
  .section2__boxes {
    display: flex;
    justify-content: space-between;
  }
  .section2__box {
    width: 30%;
  }
}

/* SECTION 3 */
.section3 h5 {
  margin: 12px 0;
}

.section3 .hero__img {
  display: none;
}
.section3 .hero__text {
  max-width: none;
}

/* SECTION 4 */
.section4 {
  background-color: #fff;
}
.section4 h5 {
  margin: 12px 0;
}

/* SECTION 5 */
.section2__box-img img {
  object-position: 30% 30%;

}

/* SECTION 6 */
.section6 .hero__img {
  display: none;
}
.section6 h5 {
  margin: 4px 0;
}
@media (min-width: 768px) {
  .section6 .hero__text {
      max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .section6 {
      justify-content: center;
  }
}
/* Start form styles GPT*/
.section6__inputs input,
.section6__inputs textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.section6__inputs input:focus,
.section6__inputs textarea:focus {
  border-color: #6bb7be; 
  outline: none;
}

.section6__input-wrapper h5 {
  margin-bottom: 5px;
  font-weight: 600;
}

.section6__inputs textarea {
  resize: none;
}

.section6 button {
  background-color: #f89646; 
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.section6 button:hover {
  background-color: #000000; 
}

.section6 button:active {
  transform: scale(0.98); 
}

/* End form styles GPT*/