/* START INFO SITE STYLES */
.page {
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    color: #000000;
}

.section {
    padding: 4px 15px;
    max-width: 1800px;
    margin: 0 auto;
}

a {
    color: #6bb7be;
    text-decoration: none;
}

@media(min-width: 768px) {
    .section {
        padding: 44px 30px;
    }
  }
/* END INFO SITE STYLES */

  /* START STORE STYLES */
  .store {
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    background-color: #f7fafa;
    min-height: 100vh;
  }

  .store__section {
    padding: 4px 15px;
    max-width: 1536px;
    margin: 0 auto;
  }

  @media(min-width: 768px) {
    .store__section {
        padding: 44px 30px;
    }
  }
  /* END STORE STYLES */

  /* START DASHBOARD STYLES */
  .dashboard {
    padding: 12px;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    background-color: #f7fafa;
    min-height: 100vh;
  }
  .dashboard__section {
    padding: 4px 15px;
    max-width: 1536px;
    margin: 0 auto;
  }

  @media(min-width: 768px) {
    .dashboard__section {
      /*padding: 44px 30px;*/
    }
  }
  /* END DASHBOARD STYLES */

/*
colors:
#000000 text
#f89646 primary
#6bb7be secondary
#f7fafa background 
*/