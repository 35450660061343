/* Container for the hero section */
.store-welcome__hero {
  padding: 20px;
  background-color: #f7fafa; /* Background color from your brand */
  display: flex;
  flex-direction: column; /* Default to column on mobile */
 /* align-items: center; 
  text-align: center; */
  max-width: 1536px;
  margin: 0 auto; /* Center content */
}

.store-welcome__host-profile-wrapper {
  padding: 0 20px 0 20px;
}

/* Flex container for the content and property info */
.store-welcome__content-wrapper {
  display: flex;
  flex-direction: column; /* Stack elements on mobile */
  width: 100%;
}

/* Welcoming content */
.store-welcome__welcoming-content {
  flex: 1;
  text-align: left;
  padding: 20px;
}

/* Welcome services */
.store-welcome__services-title {
  font-size: 14px;
  margin-bottom: 0;
}
.store-welcome__service-wrapper {
  display: flex;
  overflow: auto;
  scrollbar-width: auto;
}
.store-welcome__service {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5rem;
  flex: 1 1 calc(100% / 3);
}
.store-welcome__service-img {
  width: 150px;
  height: 200px;
  
}
.store-welcome__service-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.store-welcome__service-title {
  color: #000;
  text-align: center;
  max-width: 150px;
}
/* scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.store-welcome__service-wrapper::-webkit-scrollbar {
  width: 6px; /* Make the scrollbar narrow */
  height: 6px; /* Make horizontal scrollbar narrow */
}

.store-welcome__service-wrapper::-webkit-scrollbar-track {
  background: #f7fafa; /* Light background for scrollbar track */
}

.store-welcome__service-wrapper::-webkit-scrollbar-thumb {
  background-color: lightgray; /* Use primary color for the scrollbar */
  border-radius: 10px; /* Rounded scrollbar for a more elegant look */
}

/* firefox */
.store-welcome__service-wrapper {
  scrollbar-width: thin;
  scrollbar-color: lightgray #f7fafa; /* Thumb color and track background */
}

/* Property info */
.store-welcome__property-info {
  flex: 1;
  padding: 20px;
  max-width: 600px;
  margin: 20px auto;
}
.store-welcome__property-info-section h3 {
  margin-bottom: 0;
}
.store-welcome__property-info-section p {
  margin: 0;
}

/* Profile image */
.store-welcome__host-profile-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #f89646;
  margin-bottom: 10px;
  display: block; /* Ensure it's displayed as a block element */
  margin-left: 0; /* Align the image to the left */
}

.store-welcome__host-profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Host name styling */
.store-welcome__host-name {
  font-size: 18px;
  color: #000000;
}

/* Address styling */
.store-welcome__whatsapp {
  font-size: 16px;
  color: #6bb7be; /* Use your brand's secondary color */
  margin-bottom: 15px;
  margin-top: 0;
  font-weight: 400;
}

/* Title styling */
.store-welcome__title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 2px;
  margin-bottom: 4px;
}

/* Paragraph styling */
.store-welcome__paragraph {
  font-size: 16px;
  color: #333333;
  line-height: 1.5;
  max-width: 600px;
  margin-bottom: 12px;
}

/* Property Info (dropdowns) */
.store-welcome__property-info {
  width: 90%;
  max-width: 600px;
  margin: 2px auto; /* Center the dropdowns */
  text-align: left; /* Align content to the left */
}

.store-welcome__property-info-title {
  font-weight: bold;
  font-size: 18px;
  color: #000000; /* Text color */
  margin-bottom: 15px; /* Space between the title and accordion */
  margin-top: 2px;
  text-align: left; /* Align to the left for consistency */
}

.store-welcome__property-info .dropdown {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.store-welcome__property-info .dropdown-title {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.store-welcome__property-info .dropdown-content {
  display: none;
  font-size: 14px;
  color: #333333;
  padding-top: 10px;
}

.store-welcome__property-info .dropdown.active .dropdown-content {
  display: block;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .store-welcome__content-wrapper {
    flex-direction: row; /* Place elements side by side on desktop */
    justify-content: space-between;
    align-items: flex-start;
  }

  .store-welcome__welcoming-content {
    text-align: left;
    padding-right: 30px; /* Add spacing between columns */
    max-width: 50%; /* Take up half of the space */
  }

  .store-welcome__property-info {
    max-width: 50%; /* Take up half of the space */
  }
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .store-welcome__title {
    font-size: 22px;
  }

  .store-welcome__paragraph {
    font-size: 14px;
  }

  .store-welcome__host-profile-img {
    width: 60px;
    height: 60px;
  }

  .store-welcome__host-name {
    font-size: 16px;
  }

  .store-welcome__whatsapp {
    font-size: 14px;
  }


  .store-welcome__property-info {
    padding-top: 0;
  }
  .store-welcome__property-info .dropdown {
    padding: 10px;
  }

  .store-welcome__property-info .dropdown-title {
    font-size: 14px;
  }
}
