.footer {
    padding: 12px 27px 0 27px;
    max-width: 1800px;


}
.footer__content {
    background-color: #000000;
    color: #fff;
    text-align: center;
    font-size: 14px;
    /*min-height: 200px;*/
}

@media(min-width: 768px) {
    .footer {
        padding: 12px 42px 0 42px;
    }
  }
