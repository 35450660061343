.header {
    padding: 12px 27px 0 27px;
    max-width: 1800px;
}
.header__content {
    display: flex;
    justify-content: space-between;
}
.header__logo {
    margin-top: 10px;
}
.header__logo img {
    width: 132px;
}
.header__menu {
    margin-top: 10px;
}
.header__register-btn {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    transition: all .3s;
}
.header__register-btn:hover {
    border-bottom: 1px solid #000;
}
.header__register-btn span {
    /*margin-right: 6px;*/
}
.header__register-btn svg {
    margin-bottom: -4px;
}
@media(min-width: 768px) {
    .header {
        padding: 12px 42px 0 42px;
    }
  }
